import { all, call, takeEvery, put, fork } from 'redux-saga/effects';
import actions from './actions';
import API from 'src/utils/API';
import Notification from 'src/components/notification';
import { updateProfile } from 'src/utils/utility';

export function* fetchTutorials() {
  yield takeEvery(actions.LIST_TUTORIALS, function* () {
    try {
      const tutorials = yield call(API.settings.getTutorials);
      if (tutorials) {
        yield put({
          type: actions.LIST_TUTORIAL_SUCCESS,
          tutorials: tutorials
        });
      }
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export function* updateVideo() {
  yield takeEvery(actions.UPLOAD_VIDEO, function* (action) {
    try {
      const payload = {};
      payload.asset = action.payload.assetId;
      payload.active = action.active;
      if (action.payload && action.payload.fileName) {
        const file = yield call(API.common.getSignedURL, action.payload);
        if (file && file._id) {
          payload.asset = file._id;
          delete payload.assetId;
          yield call(API.common.uploadToS3, file.url, action.file);
        }
      }
      if (!payload.asset) {
        delete payload.asset;
      }
      yield call(API.settings.updateTutorial, payload, action.screenId);
      const tutorials = yield call(API.settings.getTutorials);
      if (tutorials) {
        yield put({
          type: actions.LIST_TUTORIAL_SUCCESS,
          tutorials: tutorials
        });
      }
      yield call(action.cb);
    } catch (err) {
      yield call(action.cb);
      Notification('error', 'Invalid request', err.errors);
    }
  });
}

export function* updateUserPreferences() {
  yield takeEvery(actions.UPDATE_PREFERENCE, function* (action) {
    try {
      yield call(API.settings.updatePreferences, action.payload, action.userId);
      updateProfile(action.payload.tutorials);
    } catch (err) {
      Notification('error', 'Invalid request', err.error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchTutorials),
    fork(updateVideo),
    fork(updateUserPreferences)
  ]);
}
