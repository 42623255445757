import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core';

import NavBar from '../containers/NavBar';
import TopBar from '../containers/Topbar';
import PrivateRouter from '../router/PrivateRouter';
import { getProfile } from 'src/redux/auth/selectors';
import { useLocation } from 'react-router';
import 'src/css/addOn.css';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  fullWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
    //   paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const PublicLayout = ({ match }) => {
  const classes = useStyles();
  const [isNavOpen, setNavOpen] = useState(true);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const user = useSelector(getProfile());
  const location = useLocation();

  useEffect(() => {
    //console.log('location.pathname', location.pathname);

    setNavOpen(
      !location.pathname.includes('studio') &&
        !location.pathname.includes('taking') &&
        !location.pathname.includes('translate')
    );
  }, [location]);

  return (
    <div className={classes.root}>
      {isNavOpen && (
        <TopBar
          onMobileNavOpen={() => setMobileNavOpen(true)}
          isNavOpen={isNavOpen}
        />
      )}
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        isNavOpen={isNavOpen}
      />
      <div className={isNavOpen ? classes.wrapper : classes.fullWrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <PrivateRouter user={user} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
