import React, { Suspense, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from 'src/components/common/LoadingScreen';
import {
  CLIENT,
  MIXER,
  TRANSLATOR,
  ACTOR,
  DIRECTOR,
  PRODUCER,
  MANAGER
} from 'src/utils/userRoles';
import asyncComponent from 'src/utils/AsyncFunc';

const ProjectDetailsView = React.lazy(() =>
  import('src/components/containers/Project/ProjectDetailsView')
);

const PrivateRouter = ({
  user: {
    role: { handle: userRole }
  }
}) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      <Route
        exact
        path={`/reports`}
        component={lazy(() => import('src/components/containers/Report'))}
      />
      <Route
        exact
        path={`/projects/oldProjects`}
        component={lazy(() => {
          //console.log('userRole', userRole);
          switch (userRole) {
            case MIXER:
              return import('src/components/views/mixer/Historic');
            case CLIENT:
              return import('src/components/views/client/Historic');
            case TRANSLATOR:
              return import('src/components/views/translator/Historic');
            case ACTOR:
              return import('src/components/views/actor/Historic');
            case DIRECTOR:
              return import('src/components/views/director/Historic');
            case PRODUCER:
              return import('src/components/views/producer/Historic');
            case MANAGER:
              return import('src/components/views/manager/Historic');
            default:
          }
        })}
      />

      {/* --- PROJECTS --- */}
      <Route
        exact
        path={`/projects/:id/taking`}
        component={lazy(() =>
          import('src/components/containers/Studio/Taking')
        )}
      />

      <Route
        exact
        path={`/projects/:id/translate`}
        component={lazy(() =>
          import('src/components/containers/Studio/Taking')
        )}
      />

      <Route
        exact
        path={`/projects/:id/edit`}
        component={lazy(() =>
          import('src/components/containers/Project/ProjectCreateView')
        )}
      />

      <Route
        exact
        path={`/projects/:id/activate`}
        component={lazy(() =>
          import('src/components/containers/Project/ProjectCreateView')
        )}
      />

      <Route
        exact
        path={`/projects/create`}
        component={asyncComponent(() =>
          import('src/components/containers/Project/ProjectCreateView')
        )}
      />

      {/* --- TRANSLATOR --- */}
      <Route
        exact
        path={`/projects/translationPending`}
        component={lazy(() =>
          import('src/components/views/translator/TranslationPending')
        )}
      />
      {/* --- MIXER --- */}
      <Route
        exact
        path={`/projects/exportPending`}
        component={lazy(() =>
          import('src/components/views/mixer/ExportPending')
        )}
      />
      <Route
        exact
        path={`/projects/pendingTaking`}
        component={lazy(() =>
          import('src/components/views/mixer/PendingTaking')
        )}
      />
      <Route
        exact
        path={`/projects/qaPending`}
        component={lazy(() => {
          if (userRole === MIXER)
            return import('src/components/views/mixer/QaPending');
          if (userRole === CLIENT)
            return import('src/components/views/client/QaPending');
          if (userRole === DIRECTOR)
            return import('src/components/views/director/QaPending');
        })}
      />
      <Route
        exact
        path={`/projects/:pId/exportDetails`}
        component={lazy(() =>
          import('src/components/containers/Page/TechMixer/exportDetails')
        )}
      />
      {/* --- CLIENT --- */}
      <Route
        exact
        path={`/projects/activeProjects`}
        component={lazy(() => {
          if (userRole === CLIENT)
            return import('src/components/views/client/ActiveProjects');
          if (userRole === PRODUCER)
            return import('src/components/views/producer/ActiveProjects');
          if (userRole === MANAGER)
            return import('src/components/views/manager/ActiveProjects');
        })}
      />
      <Route
        exact
        path={`/projects/castPending`}
        component={lazy(() =>
          import('src/components/views/client/CastingPending')
        )}
      />
      {/* --- ACTOR --- */}
      <Route
        exact
        path={`/projects/dubPending`}
        component={lazy(() => import('src/components/views/actor/DubPending'))}
      />
      <Route
        exact
        path={`/projects/invitationPending`}
        component={lazy(() =>
          import('src/components/views/actor/InvitationPending')
        )}
      />
      <Route
        exact
        path={`/projects/retakes`}
        component={lazy(() => import('src/components/views/actor/Retakes'))}
      />

      <Route
        exact
        path={`/projects/:id/studio/:characterId`}
        component={lazy(() =>
          import('src/components/containers/Studio/studioLanding')
        )}
      />
      {/* --- DIRECTOR --- */}
      <Route
        exact
        path={`/projects/textReviewPending`}
        component={lazy(() =>
          import('src/components/views/director/TextReviewPending')
        )}
      />
      <Route
        exact
        path={`/projects/castingPending`}
        component={lazy(() =>
          import('src/components/views/director/CastingPending')
        )}
      />
      <Route
        exact
        path={`/projects/:projectId/studioQA`}
        component={lazy(() =>
          import('src/components/containers/Studio/directorStudioLanding')
        )}
      />
      {/* --- PRODUCTOR --- */}
      <Route
        exact
        path={`/projects/ActivationPending`}
        component={lazy(() =>
          import('src/components/views/producer/ActivationPending')
        )}
      />
      <Route
        exact
        path={`/projects/castAssignPending`}
        component={lazy(() =>
          import('src/components/views/producer/CastingAssignPending')
        )}
      />
      <Route
        exact
        path={`/projects/castReviewPending`} // Cliente y productor
        component={lazy(() =>
          import('src/components/views/producer/CastingReviewPending')
        )}
      />
      {/* --- MANAGER --- */}
      <Route
        exact
        path={`/projects/financialList`}
        component={asyncComponent(() =>
          import('src/components/views/manager/FinancialList')
        )}
      />
      <Route
        exact
        path={`/projects/prices`}
        component={asyncComponent(() =>
          import('src/components/views/manager/Prices')
        )}
      />
      <Route
        exact
        path={`/clients`}
        component={asyncComponent(() =>
          import('src/components/views/manager/clients/ClientListView')
        )}
      />

      <Route
        exact
        path={`/clients/create`}
        component={asyncComponent(() =>
          import('src/components/views/manager/clients/ClientCreateView')
        )}
      />

      <Route
        exact
        path={`/clients/:userId`}
        component={asyncComponent(() =>
          import('src/components/containers/Account/AccountDetailsView')
        )}
      />

      <Route
        exact
        path={`/clients/:userId/edit`}
        component={asyncComponent(() =>
          import('src/components/containers/Account/AccountEditView')
        )}
      />

      <Route
        exact
        path={`/users`}
        component={asyncComponent(() =>
          import('src/components/views/manager/users/UsersListView')
        )}
      />

      <Route
        exact
        path={`/users/create`}
        component={asyncComponent(() =>
          import('src/components/views/manager/users/UserCreateView')
        )}
      />

      <Route
        exact
        path={`/users/:userId`}
        component={asyncComponent(() =>
          import('src/components/containers/Account/AccountDetailsView')
        )}
      />

      <Route
        exact
        path={`/users/:userId/edit`}
        component={asyncComponent(() =>
          import('src/components/containers/Account/AccountEditView')
        )}
      />

      {/* --- ADMIN --- */}
      <Route
        exact
        path={`/projects`}
        component={asyncComponent(() =>
          import('src/components/views/admin/Projects')
        )}
      />

      {/* --- user account --- */}
      <Route
        exact
        path={`/profile/:userId`}
        component={asyncComponent(() =>
          import('src/components/containers/Account/AccountDetailsView')
        )}
      />
      <Route
        exact
        path={`/profile/:userId/edit`}
        component={asyncComponent(() =>
          import('src/components/containers/Account/AccountEditView')
        )}
      />

      {/* --- projects --- */}
      <Route
        exact
        path={`/projects/:id/casting/:characterId`}
        component={lazy(() =>
          import(
            'src/components/containers/Project/ProjectDetailsView/Casting/Actors'
          )
        )}
      />
      <Route
        exact
        path={`/projects/:id/casting`}
        render={() => {
          return <ProjectDetailsView tab={'casting'}></ProjectDetailsView>;
        }}
      />

      <Route
        exact
        path={[
          '/projects/:id/:characterId/:notfId',
          '/projects/:id/:characterId',
          '/projects/:id'
        ]}
        component={lazy(() =>
          import('src/components/containers/Project/ProjectDetailsView')
        )}
      />

      {
        <Route
          exact
          path={`/docs`}
          component={asyncComponent(() => import('src/components/views/docs'))}
        />
      }
      <Route component={() => <Redirect to="/404" />} />
    </Switch>
  </Suspense>
);

export default PrivateRouter;
