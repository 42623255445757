import request from 'src/utils/request';
import { buffers, eventChannel, END } from 'redux-saga';

const defaultOptions = { credentials: 'same-origin', headers: {} };
const methodsWithPayload = ['POST', 'PUT', 'PATCH', 'DELETE'];
const API_URL = `${process.env.REACT_APP_API_URL}/api`;

export var progressEvt = [];
export var subidosOk = [];
export var subiendoArchivo = 0;
export function sacaEvt() {
  return progressEvt.shift();
}
export function setSubiendo() {
  subiendoArchivo++;
  console.log('NS+', subiendoArchivo);
}

/* eslint-disable no-underscore-dangle */
function _apiCall(path, options = {}) {
  const defaultedOptions = Object.assign({}, defaultOptions, options);

  if (
    methodsWithPayload.indexOf(defaultedOptions.method) > -1 &&
    defaultedOptions.body
  ) {
    defaultedOptions.headers['Content-Type'] = 'application/json';
    defaultedOptions.body =
      typeof defaultedOptions.body === 'string'
        ? defaultedOptions.body
        : JSON.stringify(defaultedOptions.body);
  }
  if (defaultedOptions.isActivation) {
    defaultedOptions.headers.Authorization = `Bearer ${getUserTempToken()}`;
  }
  if (defaultedOptions.auth) {
    defaultedOptions.headers.Authorization = `Bearer ${getUserAuthToken()}`;
  }

  let defaultedPath = typeof path === 'string' ? path : path.join('/');

  if (defaultedOptions.params) {
    const esc = encodeURIComponent;
    const query = Object.keys(defaultedOptions.params)
      .map((k) => `${esc(k)}=${esc(defaultedOptions.params[k])}`)
      .join('&');
    defaultedPath = `${defaultedPath}?${query}`;
  }

  // console.log('API call', `${API_URL}/${defaultedPath}`, defaultedOptions);

  return request(`${API_URL}/${defaultedPath}`, defaultedOptions).then(
    (result) => result
  );
}

function getUserAuthToken() {
  return localStorage.getItem('id_token');
}

function getUserTempToken() {
  return localStorage.getItem('temp_token');
}

function makeRequest(url, file) {
  return new Promise((resolve, reject) => {
    //console.log("file",file);

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.setRequestHeader('Cache-Control', 'public,max-age=3600');

    xhr.upload.onprogress = function (evt) {
      if (evt.lengthComputable) {
        progressEvt.push(evt);
        //    console.log("evt", evt.loaded, evt.total);
      }
    };

    xhr.onload = function () {
      //  console.log("this.status", this);
      if (this.status >= 200 && this.status < 300) {
        if (subiendoArchivo) subiendoArchivo--;
        //   console.log("NS-", subiendoArchivo);
        let data = url;
        //   console.log("API Result", data);
        const fs = decodeURI(data.split('/')[5].split('?')[0]);
        //   console.log("fs", fs);
        subidosOk.push(fs);

        resolve(data);
      } else {
        if (subiendoArchivo) subiendoArchivo--;
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      }
    };
    xhr.onerror = function () {
      if (subiendoArchivo) subiendoArchivo--;
      reject({
        status: this.status,
        statusText: xhr.statusText
      });
    };
    //console.log("API call", url, file);
    xhr.send(file);
  });
}

let callId = 0;
export const call =
  process.env.NODE_ENV === 'production'
    ? _apiCall
    : (path, options = {}, json = true, ...rest) => {
        callId += 1;
        const thisCallId = callId;
        return _apiCall(path, options, json, ...rest).then(
          (result) => {
            return result;
          },
          (error) => {
            console.error(
              'API Error',
              thisCallId,
              error.stack || error.message || error
            );
            throw error;
          }
        );
      };

export default {
  auth: {
    login(payload) {
      return call(['users', 'signin'], {
        method: 'POST',
        body: payload
      });
    },
    verifyOTP(payload) {
      return call(['users', 'verifyOTP'], {
        method: 'POST',
        body: payload
      });
    },
    userPreferences(userId) {
      return call(['users', userId, 'preferences'], {
        method: 'GET',
        auth: true
      });
    }
  },
  common: {
    getSignedURL(payload) {
      return call(['assets'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    getBulkSignedURL(payload) {
      return call(['assets', 'bulk'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    getSignedURLArray(projectId, payload) {
      return call(['projects', projectId, 'assetsList'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    getSignedURLProject(projectId, payload) {
      return call(['projects', projectId, 'assets'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    uploadToS3(preSignedUrl, file) {
      return makeRequest(preSignedUrl, file);
    },
    createUploadFileChannel(endpoint, file) {
      return eventChannel((emitter) => {
        const xhr = new XMLHttpRequest();
        const onProgress = (e) => {
          if (e.lengthComputable) {
            const progress = e.loaded / e.total;
            emitter({ progress });
          }
        };
        const onFailure = (e) => {
          emitter({ err: new Error('Upload failed') });
          emitter(END);
        };
        xhr.upload.addEventListener('progress', onProgress);
        xhr.upload.addEventListener('error', onFailure);
        xhr.upload.addEventListener('abort', onFailure);
        xhr.onreadystatechange = () => {
          const { readyState, status } = xhr;
          if (readyState === 4) {
            if (status === 200) {
              emitter({ success: true });
              emitter(END);
            } else {
              onFailure(null);
            }
          }
        };
        xhr.open('PUT', endpoint);
        xhr.setRequestHeader('Cache-Control', 'public,max-age=3600');
        xhr.send(file);
        return () => {
          xhr.upload.removeEventListener('progress', onProgress);
          xhr.upload.removeEventListener('error', onFailure);
          xhr.upload.removeEventListener('abort', onFailure);
          xhr.onreadystatechange = null;
          xhr.abort();
        };
      }, buffers.sliding(2));
    },
    clearAllNotifications() {
      return call(['notifications', 'markAllRead'], {
        method: 'PUT',
        auth: true
      });
    }
  },
  project: {
    listProjectAssets(payload) {
      payload.limit = 5000;
      return call(['projects'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    check(payload) {
      return call(['projects', 'check'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    details(id) {
      return call(['projects', id], {
        method: 'GET',
        auth: true
      });
    },
    characterAssigneeUpdate(payload, id) {
      return call(['projects', id, 'characters', payload._id, 'assignee'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    getAsset(id) {
      return call(['assets', id], {
        method: 'GET',
        auth: true
      });
    },
    getCharacters(pId) {
      return call(['projects', pId, 'characters'], {
        method: 'GET',
        auth: true
      });
    },
    getCharacter(pId, id) {
      return call(['projects', pId, 'characters', id], {
        method: 'GET',
        auth: true
      });
    },
    ratingEnabled(projectId, payload) {
      return call(['projects', projectId, 'rating'], {
        method: 'PUT',
        auth: true,
        body: payload
      });
    },
    closedProject(val) {
      return call(['projects', val, 'state'], {
        method: 'PUT',
        body: { state: 'CLOSED' },
        auth: true
      });
    },
    getKinds() {
      return call(['projects', 'kinds'], {
        method: 'GET',
        auth: true
      });
    },
    setKind(payload) {
      return call(['projects', 'kinds'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    create(payload) {
      return call(['projects'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    addCasting(payload, projectId) {
      return call(['projects', projectId, 'characters'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    deleteCasting(projectId, characterId) {
      return call(['projects', projectId, 'characters', characterId], {
        method: 'DELETE',
        auth: true
      });
    },
    fetchCasting(projectId) {
      return call(['projects', projectId, 'characters'], {
        method: 'GET',
        auth: true
      });
    },
    update(payload, pId) {
      return call(['projects', pId], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    delete(projectId) {
      return call(['projects', projectId], {
        method: 'DELETE',
        auth: true
      });
    },
    notifications() {
      return call(['notifications'], {
        method: 'GET',
        auth: true
      });
    },
    projectFeedback(payload, data) {
      return call(['notifications', payload.notification_id, 'feedback'], {
        method: 'POST',
        body: data,
        auth: true
      });
    },
    readNotification(payload, isRead) {
      return call(['notifications', payload.notification_id], {
        method: 'PUT',
        body: isRead,
        auth: true
      });
    },
    dub(payload) {
      return call(['projects', 'dub'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    old(payload) {
      return call(['projects', 'old'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    pendingAcceptance() {
      return call(['projects', 'pendingAcceptance'], {
        method: 'GET',
        auth: true
      });
    },
    qaPending(payload) {
      return call(['projects', 'dub'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    qaPendingList(payload) {
      return call(['projects', 'QA'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    list(payload) {
      payload.limit = 1000;
      return call(['projects'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    getCharAssigned(projectId) {
      return call(['projects', projectId, 'characters', 'assigned'], {
        method: 'GET',
        auth: true
      });
    },
    saveRecording(payload, projectId, characterId) {
      console.log('Save Recording', payload);
      return call(
        [
          'projects',
          projectId,
          'characters',
          characterId,
          'recordings',
          'update'
        ],
        {
          method: 'PUT',
          body: payload,
          auth: true
        }
      );
    },
    replaceRecording(assetId) {
      return call(['assets', assetId, 'put'], {
        method: 'GET',
        auth: true
      });
    } /* Esto pone el character.state = '
    submit(payload, projectId, characterId) {
      return call(
        ['projects', projectId, 'characters', characterId, 'recordings'],
        {
          method: 'PUT',
          body: payload,
          auth: true
        }
      );
    },*/,
    submitRetake(payload, projectId, characterId) {
      return call(
        ['projects', projectId, 'characters', characterId, 'recordingsRetake'],
        {
          method: 'PUT',
          body: payload,
          auth: true
        }
      );
    },
    exportCompltedList(payload) {
      return call(['projects', 'exportCompleted'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    exportPendingList(payload) {
      return call(['projects', 'techExport'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    exportDetails(projectId) {
      return call(['projects', projectId, 'details'], {
        method: 'GET',
        auth: true
      });
    },
    uploadVoice(projectId, payload) {
      return call(['projects', projectId, 'voiceFile'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    export(projectId) {
      return call(['projects', projectId, 'export'], {
        method: 'GET',
        auth: true
      });
    },
    exportToAAF(projectId, payload) {
      return call(['projects', projectId, 'export', 'aaf'], {
        method: 'POST',
        auth: true,
        body: payload
      });
    },
    updateState(projectId, payload) {
      return call(['projects', projectId, 'state'], {
        method: 'PUT',
        auth: true,
        body: payload
      });
    },
    changeUser(projectId, payload) {
      return call(['projects', projectId, 'updateUser'], {
        method: 'PUT',
        auth: true,
        body: payload
      });
    },
    financial(payload) {
      return call(['projects', 'export'], {
        method: 'GET',
        auth: true,
        params: payload
      });
    }
  },
  users: {
    list(payload) {
      return call(['users'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    listPagination(payload) {
      return call(['users', 'pagination'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    details(userId) {
      return call(['users', userId], {
        method: 'GET',
        auth: true
      });
    },
    update(payload, userId, isActivation) {
      return call(['users', userId], {
        method: 'PUT',
        body: payload,
        auth: true,
        isActivation
      });
    },
    activate(payload, userId, isActivation) {
      return call(['users', userId], {
        method: 'PUT',
        body: payload,
        isActivation
      });
    },
    getVoiceSamples(userId) {
      return call(['users', userId, 'voiceSamples'], {
        method: 'GET',
        auth: true
      });
    },
    updateVoiceSamples(payload, userId) {
      return call(['users', userId, 'voiceSamples'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    updateAvatar(payload, userId) {
      return call(['users', userId, 'avatar'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    getRoles() {
      return call(['users', 'roles'], {
        method: 'GET',
        auth: true
      });
    },
    checkEmail(payload) {
      return call(['users', 'checkEmail'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    addGuest(payload) {
      return call(['users', 'guest'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    getGuestDetails(payload) {
      return call(['users', 'guestDetails'], {
        method: 'GET',
        params: payload,
        auth: false
      });
    },
    createLanguage(payload) {
      return call(['users', 'languages'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    getLanguages() {
      return call(['users', 'languages'], {
        method: 'GET'
        //  auth: false
      });
    },
    getLanguageDetails(payload) {
      return call(['users', 'languages'], {
        method: 'GET',
        params: payload
        //     auth: true
      });
    },
    setLanguage(payload) {
      return call(['users', 'languages'], {
        method: 'PUT',
        params: payload
        //   auth: true
      });
    },
    deleteLanguage(langId) {
      return call(['users', 'languages', langId], {
        method: 'DELETE',
        auth: true
      });
    }
  },
  characters: {
    list(id) {
      return call(['projects', id, 'characters'], {
        method: 'GET',
        auth: true
      });
    },
    fetchAudio(projectId, characterId) {
      return call(['projects', projectId, 'characters', characterId], {
        method: 'GET',
        auth: true
      });
    },
    fetchAudios(projectId) {
      return call(['projects', projectId, 'characters', 'recordings'], {
        method: 'GET',
        auth: true
      });
    },
    getStatus(payload, id) {
      return call(['projects', id, 'characters', 'assigned'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    cast(payload, id) {
      return call(['projects', id, 'characters'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    updateDubbing(payload, id) {
      return call(['projects', id, 'characters', 'dubbing'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    castByCharacter(payload, id) {
      return call(['projects', id, 'characters', payload._id], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    updateCharacter(payload, id) {
      return call(['projects', id, 'characters', payload._id, 'assignee'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    setCharTime(payload, id) {
      return call(['projects', id, 'characters', payload._id, 'time'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    setCharExport(payload, id) {
      return call(['projects', id, 'characters', payload._id, 'export'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    setCharTakesStates(payload, pId, cId) {
      //  console.log('PAYLOAD', payload);
      return call(['projects', pId, 'characters', cId, 'takesStates'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    }
  },

  subtitles: {
    list(id) {
      return call(['projects', id, 'subtitles'], {
        method: 'GET',
        auth: true
      });
    },
    update(payload, id) {
      return call(['projects', id, 'subtitles'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    take(payload, id) {
      return call(['projects', id, 'subtitles', 'take'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    takeAsset(payload) {
      return call(['assets', 'file'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    }
  },
  retakes: {
    list(payload) {
      return call(['projects', 'retakes'], {
        method: 'GET',
        params: payload,
        auth: true
      });
    },
    details(projectId, retakeId) {
      return call(['projects', projectId, 'retakes', retakeId], {
        method: 'GET',
        auth: true
      });
    },
    passiveList(id) {
      return call(['projects', id, 'retakes'], {
        method: 'GET',
        auth: true
      });
    },
    submit(payload, id) {
      return call(['projects', id, 'retakes'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    save(payload, id) {
      return call(['projects', id, 'retakes'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    batchSave(payload, id) {
      return call(['projects', id, 'retakes', 'bulk'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    update(payload, id, takeId) {
      return call(['projects', id, 'retakes', takeId], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    delete(id, takeId) {
      return call(['projects', id, 'retakes', takeId], {
        method: 'DELETE',
        auth: true
      });
    }
  },
  actor: {
    notify(payload, id) {
      return call(['notifications', id, 'feedback'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    }
  },
  timeLine: {
    fetch(id) {
      return call(['projects', id, 'timeline'], {
        method: 'GET',
        auth: true
      });
    }
  },
  password: {
    reset(payload, id) {
      return call(['users', id, 'password', 'change'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    set(payload) {
      return call(['users', 'password', 'set'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    forgot(payload) {
      return call(['users', 'password', 'reset'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    }
  },
  assets: {
    get(id) {
      return call(['assets', id], {
        method: 'GET',
        auth: true
      });
    }
  },
  director: {
    approve(payload, id) {
      return call(['projects', id, 'characters', 'status'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    }
  },
  chats: {
    getChats(id) {
      return call(['chats', 'channels', id, 'messages'], {
        method: 'GET',
        auth: true
      });
    },
    requestContacts() {
      return call(['chats', 'users'], {
        method: 'GET',
        auth: true
      });
    },
    uploadAudio(payload) {
      return call(['assets'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    uploadToUrl(url, payload) {
      return call([url], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    channel(payload, channelId) {
      return call(['chats', 'channels', channelId, 'messages'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    markRead(payload) {
      return call(['chats', 'messages', 'read'], {
        method: 'PUT',
        body: payload,
        auth: true
      });
    },
    channels(payload) {
      return call(['chats', 'channels'], {
        method: 'POST',
        body: payload,
        auth: true
      });
    },
    getChannels() {
      return call(['chats', 'channels'], {
        method: 'GET',
        auth: true
      });
    },
    clearChannel(id) {
      return call(['chats', 'channels', id, 'clear'], {
        method: 'PUT',
        auth: true
      });
    }
  }
};
